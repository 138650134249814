<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>
                fal fa-chevron-left
              </VIcon>
            </VBtn>

            <VToolbarTitle>Отправка push уведомлений</VToolbarTitle>
          </VToolbar>

          <VCardText>
            <VForm>
              <VTextField
                v-model="title"
                label="Заголовок"
              />

              <VTextField
                v-model="body"
                label="Сообщение"
              />

              <VBtn
                color="primary"
                depressed
                @click="sendPush"
              >
                Отправить сообщение
              </VBtn>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'PushSend',
  data() {
    return {
      title: '',
      body: '',
    };
  },
  computed: {
    userId() {
      return this.$route.params.userId;
    },
  },
  methods: {
    async sendPush() {
      const payload = {
        userId: this.userId,
        title: this.title,
        body: this.body,
      };

      try {
        await this.$di.api.Sysadmin.pushSend(payload);
        this.$di.notify.snackSuccess('Уведомление успешно отправлено');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
